<template>
  <b-card>
    <podcasts-table
      :options="options"
      @delete="deletePodcast"
      @edit="editPodcast"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import PodcastsTable from '../../components/table/PodcastsTable.vue'
import podcastsStoreModule from '../podcastsStoreModule'

export default {
  name: 'PresentersList',
  components: {
    PodcastsTable,
    BCard,
  },
  data() {
    return {
      storeName: 'podcasts',
      programs: null,
    }
  },
  computed: {
    options() {
      return {
        items: this.programs,
        columns: [
          {
            key: 'imageUrl', label: 'Image', sortable: false,
          },
          { key: 'title', label: 'Name', sortable: true },
          { key: 'Programmers', label: 'Presenters', sortable: true },
          { key: 'actions' },
        ],
      }
    },
  },
  async mounted() {
    await this.registerStore()
    await this.fetchPodcasts()
  },
  destroyed() {
    if (this.$store.hasModule(this.storeName)) this.$store.unregisterModule(this.storeName)
  },
  methods: {
    async fetchPodcasts() {
      let data = await this.$store.dispatch(`${this.storeName}/fetchPodcasts`, { limit: '10', page: 1, q: '' })
      data = data.map(podcast => ({
        ...podcast,
        actions: [
          {
            label: 'Edit',
            on: 'edit',
          },
          {
            label: 'Delete',
            on: 'delete',
          },
        ],
      }))
      this.programs = data
    },
    async deletePodcast($event) {
      try {
        await this.$store.dispatch(`${this.storeName}/deletePodcast`, $event)
        this.$bvToast.toast('Podcast deleted.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })
        this.fetchPodcasts()
      } catch (error) {
        this.$bvToast.toast('Getting an error while deleting podcast.', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'error',
          solid: true,
        })
      }
    },
    editPodcast(id) {
      this.$router.push({ name: 'EditPodcast', query: { id } })
    },
    registerStore() {
      if (!this.$store.hasModule(this.storeName)) this.$store.registerModule(this.storeName, podcastsStoreModule)
    },
  },
}
</script>

<style>

</style>
